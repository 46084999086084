import React, { useContext, useEffect, useMemo } from "react"
import { graphql } from "gatsby"
import { AVCOContext } from "../components/context/AVCOContext"

import Seo from "../components/common/seo"

import { ProjectsPageContent } from "../components/pagecomponents/Projects/ProjectsPageContent"

export const query = graphql`
  query ProjectsPageQuery {
    projects: allSanityProject(sort: { fields: sortDate, order: DESC }) {
      edges {
        node {
          title
          slug {
            current
          }
          displayDate
          featuredImage {
            altText
            image {
              asset {
                gatsbyImageData(aspectRatio: 1.78, width: 800)
              }
            }
          }
          hasWebsiteImage
          websiteImage {
            altText
            image {
              asset {
                gatsbyImageData(aspectRatio: 1.5, width: 800)
              }
            }
          }
          clients {
            name
            slug {
              current
            }
          }
          services {
            name
            slug {
              current
            }
          }
          displayedServices {
            name
            slug {
              current
            }
          }
          years {
            name
            slug {
              current
            }
          }
        }
      }
    }

    services: sanitySingletonProjectsPage {
      defaultServices {
        name
        slug {
          current
        }
      }
      moreServices {
        name
        slug {
          current
        }
      }
    }

    curatedProjects: allSanityCurated {
      edges {
        node {
          title
          _rawIntroduction
          description
          slug {
            current
          }
          projects {
            title
            slug {
              current
            }
          }
        }
      }
    }

    orderedCuratedFilters: sanitySingletonProjectsPage {
      curatedFilters {
        title
        slug {
          current
        }
      }
    }
  }
`

const ProjectsPage = ({ data }) => {
  const projects = data.projects.edges
  const defaultServices = data.services.defaultServices
  const moreServices = data.services.moreServices
  const curatedProjects = data.curatedProjects.edges
  const orderedCuratedFilters = data.orderedCuratedFilters.curatedFilters
  const { activeMenuItem, setActiveMenuItem } = useContext(AVCOContext)

  // GET DEFAULT FILTERS
  const defaultFilters = useMemo(() => {
    const arr = []

    defaultServices.forEach((item, index) => {
      arr.push({
        name: item?.name,
        slug: item?.slug.current,
      })
    })

    return arr
  }, [defaultServices])

  // GET MORE FILTERS
  const moreFilters = useMemo(() => {
    const arr = []

    moreServices.forEach((item, index) => {
      arr.push({
        name: item?.name,
        slug: item?.slug.current,
      })
    })

    return arr
  }, [moreServices])

  // GET CURATED FILTERS
  const curatedFilters = useMemo(() => {
    const arr = []

    orderedCuratedFilters.forEach(item => {
      arr.push({
        name: item.title,
        slug: item.slug.current,
      })
    })

    return arr
  }, [orderedCuratedFilters])

  // ACTIVE MENU ITEM
  useEffect(() => {
    setActiveMenuItem("projects")
  }, [activeMenuItem, setActiveMenuItem])

  return (
    <>
      <Seo title="AVCO Projects" />
      <ProjectsPageContent
        defaultFilters={defaultFilters}
        moreFilters={moreFilters}
        curatedFilters={curatedFilters}
        projects={projects}
        curatedProjects={curatedProjects}
      />
    </>
  )
}

export default ProjectsPage
