import React from "react"
import classNames from "classnames"

import * as styles from "./ProjectListItem.module.scss"
import { InternalExternalLink } from "../InternalExternalLink"

const ProjectListItem = ({
  listTitle = false,
  children,
  to,
  columns,
  size = "normal",
  leftBorder = false,
  rightBorder = false,
  topBorder = true,
  bottomBorder = true,
  className,
}) => {
  const cls = classNames(
    styles.item,
    [styles[size]],
    {
      [styles.listTitle]: listTitle,
      [styles.topBorder]: topBorder,
      [styles.rightBorder]: rightBorder,
      [styles.bottomBorder]: bottomBorder,
      [styles.leftBorder]: leftBorder,
    },
    className
  )

  return (
    <div className={cls}>
      <InternalExternalLink to={to}>{children}</InternalExternalLink>
    </div>
  )
}

export { ProjectListItem }
