// extracted by mini-css-extract-plugin
export var bottomBorder = "ProjectListItem-module--bottom-border--a6701";
export var content = "ProjectListItem-module--content--11fe2";
export var header1 = "ProjectListItem-module--header1--bb3da";
export var header2 = "ProjectListItem-module--header2--0a8b4";
export var header3 = "ProjectListItem-module--header3--ff218";
export var header4 = "ProjectListItem-module--header4--27d56";
export var header5 = "ProjectListItem-module--header5--51bbf";
export var icon = "ProjectListItem-module--icon--a12fd";
export var iconWrapper = "ProjectListItem-module--icon-wrapper--73c88";
export var invert = "ProjectListItem-module--invert--e0270";
export var item = "ProjectListItem-module--item--edbb8";
export var justifyEnd = "ProjectListItem-module--justify-end--87eca";
export var label = "ProjectListItem-module--label--ba86d";
export var large = "ProjectListItem-module--large--dbb1a";
export var leftBorder = "ProjectListItem-module--left-border--f2440";
export var listTitle = "ProjectListItem-module--list-title--f04c6";
export var navigation = "ProjectListItem-module--navigation--dd51c";
export var normal = "ProjectListItem-module--normal--54e2c";
export var reverse = "ProjectListItem-module--reverse--18521";
export var rightBorder = "ProjectListItem-module--right-border--e8739";
export var subMenuOne = "ProjectListItem-module--sub-menu-one--039d0";
export var subMenuThree = "ProjectListItem-module--sub-menu-three--73cb1";
export var subMenuTwo = "ProjectListItem-module--sub-menu-two--89b98";
export var topBorder = "ProjectListItem-module--top-border--323c6";