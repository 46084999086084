import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react"
import classNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import { Wrapper } from "../../../layout/Wrapper"
import { Container } from "../../../layout/Container"
import { InternalExternalLink } from "../../../common/InternalExternalLink"
import * as styles from "./ProjectsPageContent.module.scss"
import { useQueryString } from "../../../../utils/hooks/useQueryString"
import { ProjectListItem } from "../../../common/ProjectListItem"
import SanityBlockContent from "@sanity/block-content-to-react"
import { arrayofObjectstoCommaSeparatedstring } from "../../../../utils/common"
import { MediaQuery, useMediaQuery } from "../../../../utils/hooks/mediaQuery"
import { AVCOContext } from "../../../context/AVCOContext"
import { getYearsAsString } from "../../../../utils/projects"
const VIEW_MODE = {
  LIST: "list",
  IMAGE: "image",
}

const ProjectsPageContent = ({
  defaultFilters,
  moreFilters,
  curatedFilters,
  projects,
  curatedProjects,
}) => {
  const isTablet = useMediaQuery(MediaQuery.Tablet)

  const [currentTag, setTag] = useState(false)
  const [more, setMore] = useState(true)
  const [curatedTag, setCuratedTag] = useState(false)

  const [viewMode, setViewMode] = useState(VIEW_MODE.IMAGE)

  const { value: queryStringValues, updateQueryString } = useQueryString()

  // set History Flag so query string gets updated when 'Projects' menu item clicked
  const { historyChanged, setHistoryChanged } = useContext(AVCOContext)

  useEffect(() => {
    if (queryStringValues === null) {
      setCuratedTag(false)
    }
  }, [historyChanged])

  // Set visible filters depending on 'more' and 'currentTag' status
  const filters = useMemo(() => {
    const arr = []

    if (more === false) return defaultFilters
    else {
      defaultFilters.forEach((item, index) => arr.push(item))
      moreFilters.forEach((item, index) => arr.push(item))
      return arr
    }
  }, [currentTag, defaultFilters, moreFilters, more])

  // Filter clicked
  const onToggleFilter = useCallback(
    newTag => {
      setCuratedTag(false)
      if (currentTag === newTag) {
        setTag(false)
        setMore(false)
        updateQueryString(false)
      } else {
        setTag(newTag)
        // setMore(true)
        updateQueryString({ category: newTag })
      }
    },
    [currentTag, updateQueryString]
  )

  // Curated Filter clicked
  const onToggleCuratedFilter = useCallback(
    newTag => {
      setTag(false)
      if (curatedTag === newTag) {
        setCuratedTag(false)
        setMore(false)
        updateQueryString(false)
      } else {
        setCuratedTag(newTag)
        // setMore(true)
        updateQueryString({ curated: newTag })
      }
    },
    [curatedTag, updateQueryString]
  )

  // More Toggle
  const onToggleMore = () => {
    setMore(!more)
    if (more === true) {
      setTag(false)
      setCuratedTag(false)
    }
  }

  // Update on new query string and 'history' status
  useEffect(() => {
    if (historyChanged) {
      setMore(true)
    }
    setHistoryChanged(false)
    if (queryStringValues === null) {
      setTag(false)
      return
    }

    const { category, curated } = queryStringValues

    if (category) {
      // setMore(true)
      setTag(category)
    }

    if (curated) {
      setMore(true)
      setCuratedTag(curated)
    }
  }, [queryStringValues, historyChanged, setHistoryChanged, setMore])

  // All Projects
  const projectList = useMemo(() => {
    return projects.map(item => {
      return {
        name: item.node.title,
        displaydate: item.node.displayDate,
        years: item.node.years,
        slug: `/projects/${item.node.slug.current}`,
        image: item.node.hasWebsiteImage
          ? item?.node.websiteImage
          : item?.node.featuredImage,
        services: item.node.services,
        clients: item.node.clients,
        hasWebsiteImage: item.node.hasWebsiteImage,
      }
    })
  }, [projects])

  // Curated Project if 'curated' query string is set or 'curated' filter selected
  const curatedProject = useMemo(() => {
    if (!curatedTag) return
    const arrCuratedProjects = curatedProjects.filter(
      item => item.node.slug.current === curatedTag
    )
    return arrCuratedProjects[0].node
  }, [curatedProjects, curatedTag])

  // Change 'List' or 'Image' View
  const changeView = useCallback(
    force => {
      if (force && typeof force === "string") {
        setViewMode(force)
        return
      }
      // onToggleFilter(false)
      setViewMode(
        viewMode === VIEW_MODE.IMAGE ? VIEW_MODE.LIST : VIEW_MODE.IMAGE
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewMode]
  )

  //  Filter Projects based on 'currentTag' or 'curated'
  const filteredProjectList = useMemo(() => {
    const fil = items =>
      items?.filter(item => {
        if (!currentTag) {
          return true
        }
        return item.services?.some(tag => tag.slug.current === currentTag)
      })

    // curated
    if (curatedTag) {
      const arr = []

      curatedProject.projects.forEach((project, index) => {
        arr.push(
          ...projectList.filter(
            item => `/projects/${project.slug.current}` === item.slug
          )
        )
      })

      return arr
    }

    // current
    return fil(projectList)
  }, [projectList, currentTag, curatedTag, curatedProject])

  // 'cls' for 'All' active if no filter selected
  const cls = classNames(styles.filter, {
    [styles.active]: currentTag === false && curatedTag === false,
  })

  return (
    <>
      {/* FILTERS */}
      <Container className={styles.options} padded={true}>
        <div>
          {/* Services Filters */}
          <div className={styles.optionsFilters}>
            <button onClick={e => onToggleFilter(false)} className={cls}>
              All
            </button>
            {filters.map((node, index) => {
              const cls = classNames(styles.filter, {
                [styles.active]: node.slug === currentTag,
              })
              return (
                <button
                  onClick={e => onToggleFilter(node.slug)}
                  key={`tag-${node.slug}`}
                  className={cls}
                >
                  {node.name}
                </button>
              )
            })}
            {!more && (
              <button
                onClick={e => onToggleMore()}
                className={classNames(styles.listButton, styles.moreLessButton)}
              >
                {more === false ? "More..." : "Less"}
              </button>
            )}
          </div>

          {/* Curated Filters */}
          {more && (
            <div className={styles.pins}>
              <div className={styles.pinsTitle}>Pins</div>
              {curatedFilters.map((node, index) => {
                const cls = classNames(styles.filter, {
                  [styles.active]: node.slug === curatedTag,
                })
                return (
                  <button
                    onClick={e => onToggleCuratedFilter(node.slug)}
                    key={`tag-${node.slug}`}
                    className={cls}
                  >
                    {node.name}
                  </button>
                )
              })}
              <button
                onClick={e => onToggleMore()}
                className={classNames(styles.listButton, styles.moreLessButton)}
              >
                {more === false ? "More..." : "Less"}
              </button>
            </div>
          )}
        </div>
        {isTablet && (
          <button className={styles.listButton} onClick={() => changeView()}>
            {viewMode === VIEW_MODE.IMAGE ? "List View" : "Image View"}
            {/* {viewMode === VIEW_MODE.IMAGE ? '☰ List View' : '☷ Image View'} */}
          </button>
        )}
      </Container>
      {/* CURATED TITLE AND INTRO */}

      {curatedTag && (
        <Wrapper narrow>
          <div className={styles.curated}>
            <div>
              <h3 className={styles.featureHeadline}>
                <span>{curatedProject.description}</span>
              </h3>
            </div>
            <div className={styles.curatedIntro}>
              <SanityBlockContent blocks={curatedProject._rawIntroduction} />
            </div>
          </div>
        </Wrapper>
      )}

      {/* LISTING */}
      {viewMode === VIEW_MODE.IMAGE ? (
        <Wrapper narrow>
          <div className={styles.listing}>
            {filteredProjectList?.map((project, index) => {
              return (
                <div key={`project-${index}`}>
                  {project?.image && (
                    <InternalExternalLink
                      to={`${project?.slug}`}
                      className={styles.projectItem}
                    >
                      {project.hasWebsiteImage ? (
                        <div
                          className={classNames(
                            styles.sixteenNine,
                            styles.imageWrapper
                          )}
                        >
                          <GatsbyImage
                            image={project?.image?.image?.asset.gatsbyImageData}
                            alt={project?.image?.altText || `${project.name}`}
                            loading="eager"
                            className={classNames(styles.websiteImage)}
                          />
                        </div>
                      ) : (
                        <div className={styles.imageWrapper}>
                          <GatsbyImage
                            image={project?.image?.image?.asset.gatsbyImageData}
                            alt={project?.image?.altText}
                            loading="eager"
                          />
                        </div>
                      )}
                      <div>
                        <h2>{project.name}</h2>
                        <h5>{project.displaydate}</h5>
                      </div>
                    </InternalExternalLink>
                  )}
                </div>
              )
            })}
          </div>
        </Wrapper>
      ) : (
        <Wrapper className={styles.listView}>
          <ProjectListItem topBorder={false} listTitle={true}>
            <span className={styles.listYear}>Year</span>
            <span className={styles.listTitle}>Project</span>
            <span className={styles.listClients}>Client</span>
            <span className={styles.listServices}>Type</span>
          </ProjectListItem>
          {filteredProjectList.map((project, index) => {
            const years = getYearsAsString(project.years)

            return (
              <div key={`project-${index}`}>
                <ProjectListItem topBorder={false} to={project?.slug}>
                  {years && <span className={styles.listYear}>{years}</span>}

                  {project?.name && (
                    <span className={styles.listTitle}>{project.name}</span>
                  )}
                  {project.clients && (
                    <span className={styles.listClients}>
                      {arrayofObjectstoCommaSeparatedstring(project.clients)}
                    </span>
                  )}

                  {project.services && (
                    <span className={styles.listServices}>
                      {arrayofObjectstoCommaSeparatedstring(
                        project.services,
                        2
                      )}
                    </span>
                  )}
                </ProjectListItem>
              </div>
            )
          })}
        </Wrapper>
      )}
    </>
  )
}

export { ProjectsPageContent }
