import { useState, useCallback, useEffect } from "react"

import { useLocation } from "@reach/router"
import qs from "query-string"

function useQueryString({ initialValue = null } = {}) {
  const location = useLocation()
  const [value, setValue] = useState(initialValue)
  const [initialQueryRun, setInitialQuery] = useState(false)

  const updateQueryString = useCallback(
    newValue => {
      window.history.replaceState(
        null,
        null,
        `${location.pathname}?${qs.stringify(newValue)}`
      )
    },
    [location.pathname]
  )

  useEffect(() => {
    if (!location.search || initialQueryRun) {
      setInitialQuery(true)
      return
    }

    setInitialQuery(true)
    setValue(qs.parse(location.search) || {})
  }, [location, initialQueryRun])

  return { value, updateQueryString, initialQueryRun }
}

export { useQueryString }
